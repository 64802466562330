import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import type { Domain } from '@/@types/data';

export type DomainsState = {
    domains: Domain[];
    isLoading: boolean;
    error: string | null;
}

const initialState: DomainsState = {
    domains: [],
    isLoading: false,
    error: null,
};

const domainsSlice = createSlice({
    name: `${SLICE_BASE_NAME}/domains`,
    initialState,
    reducers: {
        setDomains: (state, action: PayloadAction<Domain[]>) => {
            state.domains = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setDomains, setLoading, setError } = domainsSlice.actions;

export default domainsSlice.reducer;
