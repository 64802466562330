import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiScriptsFetch } from '@/services/DataService';
import { setScripts, setLoading, setError, ScriptsState } from '@/store/slices/data/scriptsSlice';
import useAuth from './useAuth';
import { useAppSelector } from '@/store';

const useScriptsFetch = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const scriptsState = useAppSelector((state) => state.data.scriptsState)

    const fetchScripts = async () => {
        dispatch(setLoading(true));
        try {
            const response = await apiScriptsFetch();
            dispatch(setScripts(response.data)); // Adjust based on your API response
            dispatch(setLoading(false));
        } catch (error: any) {
            dispatch(setError(error.message));
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (authenticated && scriptsState.scripts.length === 0) {
            fetchScripts();
        }
    }, [dispatch, authenticated, scriptsState.scripts.length]);

    return scriptsState;
};

export default useScriptsFetch;
