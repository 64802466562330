import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import type { Currency } from '@/@types/data';

export type CurrenciesState = {
    currencies: Currency[];
    isLoading: boolean;
    error: string | null;
}

const initialState: CurrenciesState = {
    currencies: [],
    isLoading: false,
    error: null,
};

const currenciesSlice = createSlice({
    name: `${SLICE_BASE_NAME}/currencies`,
    initialState,
    reducers: {
        setCurrencies: (state, action: PayloadAction<Currency[]>) => {
            state.currencies = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setCurrencies , setLoading, setError } = currenciesSlice.actions;

export default currenciesSlice.reducer;
