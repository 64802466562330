import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import type { Partner } from '@/@types/data';

export type PartnersState = {
    partners: Partner[];
    isLoading: boolean;
    error: string | null;
}

const initialState: PartnersState = {
    partners: [],
    isLoading: false,
    error: null,
};

const partnersSlice = createSlice({
    name: `${SLICE_BASE_NAME}/partners`,
    initialState,
    reducers: {
        setPartners: (state, action: PayloadAction<Partner[]>) => {
            state.partners = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setPartners, setLoading, setError } = partnersSlice.actions;

export default partnersSlice.reducer;
