import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from '@/components/template/Theme'
import Layout from '@/components/layouts'
import './locales'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PrimeReactProvider } from 'primereact/api';

function App() {
    const environment = process.env.NODE_ENV
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                cacheTime: 0,
                refetchOnWindowFocus: false, // default: true
            },
        },
    })
    /**
     * Set enableMock(Default false) to true at configs/app.config.js
     * If you wish to enable mock api
     */

    // if (environment !== 'production' && appConfig.enableMock) {
    //     mockServer({ environment })
    // }

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <PrimeReactProvider>
                        <BrowserRouter>
                            <Theme>
                                <Layout />
                            </Theme>
                        </BrowserRouter>
                    </PrimeReactProvider>
                </PersistGate>
            </Provider>
        </QueryClientProvider>
    )
}

export default App
