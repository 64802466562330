import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'
import { Domain } from '@/@types/data'

export type UserState = {
    avatar?: string
    userName?: string
    email?: string
    authority?: string[]
    name: string
    roles: object[]
    domains: any[] 
    token: string,
    id?: number
}

const initialState: UserState = {
    avatar: '', //starter
    userName: '', //starter
    name: '',
    authority: [], //starter
    roles: [],
    email: '',
    domains: [],
    token: '',
    id: 0,
}

const userSlice = createSlice({
    name: `${SLICE_BASE_NAME}/user`,
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<UserState>) {
            state.avatar = action.payload?.avatar
            state.email = action.payload?.email
            state.userName = action.payload?.userName
            state.authority = action.payload?.authority
            state.name = action.payload?.name
            state.roles = action.payload?.roles
            state.domains = action.payload?.domains
            state.token = action.payload?.token
            state.id = action.payload?.id
        },
    },
})

export const { setUser } = userSlice.actions
export default userSlice.reducer
