import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiDomainsFetch } from '@/services/DataService';
import { setDomains, setLoading, setError, DomainsState } from '@/store/slices/data/domainsSlice';
import useAuth from './useAuth';
import { useAppSelector } from '@/store';

const useDomainsFetch = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const domainsState = useAppSelector((state) => state.data.domainsState)

    const fetchDomains = async () => {
        dispatch(setLoading(true));
        try {
            const response = await apiDomainsFetch();
            dispatch(setDomains(response.data[0])); // Adjust based on your API response
            dispatch(setLoading(false));
        } catch (error: any) {
            dispatch(setError(error.message));
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (authenticated && domainsState.domains.length === 0) {
            fetchDomains();
        }
    }, [dispatch, authenticated, domainsState.domains.length]);

    return domainsState as DomainsState;
};

export default useDomainsFetch;
