import { useState, useEffect } from 'react';
/**
 * A hook to provide a localStorage value that is kept in sync across all instances of the hook.
 * @param key The key to use in localStorage
 * @param initialValue The initial value to use if the key is not present in localStorage
 * @returns A tuple containing the value and a function to update it
 * */
function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T | ((val: T) => T)) => void] {
    // A function to read the value from localStorage and parse it from JSON
    const readValue = (): T => {
        // Check for SSR (Server-side rendering)
        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    };

    // State to store our value
    const [storedValue, setStoredValue] = useState<T>(readValue);

    // Only update local storage when key changes
    useEffect(() => {
        setStoredValue(readValue());
    }, [key]);

    // Update the local storage and state
    const setValue = (value: T | ((val: T) => T)): void => {
        if (typeof window == 'undefined') {
            console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
            return;
        }

        try {
            // Allow value to be a function so we have same API as useState
            const newValue = value instanceof Function ? value(storedValue) : value;

            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(newValue));

            // Save state
            setStoredValue(newValue);

            // Trigger a custom event to update all instances of this hook
            window.dispatchEvent(new Event('local-storage'));
        } catch (error) {
            console.warn(`Error setting localStorage key “${key}”:`, error);
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setStoredValue(readValue());
        };

        // Event listener for storage events
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('local-storage', handleStorageChange);

        // Cleanup listeners on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('local-storage', handleStorageChange);
        };
    }, []);

    return [storedValue, setValue];
}

export default useLocalStorage;
