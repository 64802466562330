import { createSlice } from '@reduxjs/toolkit'
import appConfig from '@/configs/app.config'
type DateFilterProps = {
    id: string
    value: { query: string; startDate: string; endDate: string }
}
export type TableState = {
    table: object[]
    queryPageIndex: number
    queryPageSize: number
    queryPageFilter: DateFilterProps[]
    queryPageSortBy: object[]
    queryGlobalFilter: object[]
    totalCount: number
}

const initialState: TableState = {
    table: [],
    queryPageIndex: 0,
    queryPageSize: 10,
    queryPageFilter: [],
    queryPageSortBy: [],
    queryGlobalFilter: [],
    totalCount: 0,
}

export const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setTable: (state, action) => {
            state.table = action.payload
        },
        setQueryPageSize: (state, action) => {
            state.queryPageSize = action.payload
        },
        setQueryPageIndex: (state, action) => {
            state.queryPageIndex = action.payload
        },
        setQueryPageFilter: (state, action) => {
            state.queryPageFilter = action.payload
        },
        setQueryPageSortBy: (state, action) => {
            state.queryPageSortBy = action.payload
        },
        setQueryGlobalFilter: (state, action) => {
            state.queryGlobalFilter = action.payload
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload
        },
        updateState: (state, action) => {
            state = action.payload
        },
    },
})

export const {
    setTable,
    setQueryPageSize,
    setQueryPageFilter,
    setQueryPageSortBy,
    setQueryGlobalFilter,
    setQueryPageIndex,
    setTotalCount,
    updateState,
} = tableSlice.actions

export default tableSlice.reducer
