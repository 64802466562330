import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiPartnersFetch } from '@/services/DataService';
import { setPartners, setLoading, setError, PartnersState } from '@/store/slices/data/partnersSlice';
import useAuth from './useAuth';
import { useAppSelector } from '@/store';

const usePartnersFetch = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const partnersState = useAppSelector((state) => state.data.partnersState)

    const fetchPartners = async () => {
        dispatch(setLoading(true));
        try {
            const response = await apiPartnersFetch();
            dispatch(setPartners(response.data[0])); // Adjust based on your API response
            dispatch(setLoading(false));
        } catch (error: any) {
            dispatch(setError(error.message));
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (authenticated && partnersState.partners.length === 0) {
            fetchPartners();
        }
    }, [dispatch, authenticated, partnersState.partners.length]);

    return partnersState;
};

export default usePartnersFetch;
