import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {  apiIndustriesFetch } from '@/services/DataService';
import { setIndustries, setLoading, setError, IndustriesState } from '@/store/slices/data/industriesSlice';
import useAuth from './useAuth';
import { useAppSelector } from '@/store';

const useIndustriesFetch = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const industriesState = useAppSelector((state) => state.data.industriesState)

    const fetchIndustries = async () => {
        dispatch(setLoading(true));
        try {
            const response = await apiIndustriesFetch();
            dispatch(setIndustries(response.data[0])); // Adjust based on your API response
            dispatch(setLoading(false));
        } catch (error: any) {
            dispatch(setError(error.message));
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (authenticated && industriesState.industries.length === 0) {
            fetchIndustries();
        }
    }, [dispatch, authenticated, industriesState.industries.length]);

    return industriesState as IndustriesState;
};

export default useIndustriesFetch;
