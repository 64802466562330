import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiCurrenciesFetch } from '@/services/DataService';
import { setCurrencies, setLoading, setError, CurrenciesState } from '@/store/slices/data/currenciesSlice';
import useAuth from './useAuth';
import { useAppSelector } from '@/store';

const useCurrenciesFetch = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const currenciesState = useAppSelector((state) => state.data.currenciesState)

    const fetchCurrencies = async () => {
        dispatch(setLoading(true));
        try {
            const response = await apiCurrenciesFetch();
            dispatch(setCurrencies(response.data[0])); // Adjust based on your API response
            dispatch(setLoading(false));
        } catch (error: any) {
            dispatch(setError(error.message));
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (authenticated && currenciesState.currencies.length === 0) {
            fetchCurrencies();
        }
    }, [dispatch, authenticated, currenciesState.currencies.length]);

    return currenciesState as CurrenciesState;
};

export default useCurrenciesFetch;
