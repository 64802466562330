/* eslint-disable import/default */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import 'regenerator-runtime'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(

    import.meta.env.VITE_ENV == 'local'
     ?
        <DndProvider backend={HTML5Backend}>
                <App />
        </DndProvider>
    :
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <App />
        </DndProvider>
    </React.StrictMode>

)
