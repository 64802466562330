import axios from 'axios'
import appConfig from '@/configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '@/constants/api.constant'
import { PERSIST_STORE_NAME } from '@/constants/app.constant'
import deepParseJson from '@/utils/deepParseJson'
import store, { signOutSuccess } from '../store'
import { HttpCodes } from '@/constants/api.constant'
import {  setDbName } from '@/store'


const unauthorizedCode = [401]


const BaseService = axios.create({
   timeout: 60000,
   baseURL: appConfig.apiPrefix,
})


BaseService.interceptors.request.use(
   (config) => {
       const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
       const persistData = deepParseJson(rawPersistData)


       // eslint-disable-next-line @typescript-eslint/no-explicit-any
       let accessToken = (persistData as any).auth.session.token


       if (!accessToken) {
           const { auth } = store.getState()
           accessToken = auth.session.token
       }


       if (accessToken) {
           config.headers[
               REQUEST_HEADER_AUTH_KEY
           ] = `${TOKEN_TYPE}${accessToken}`
       }


       return config
   },
   (error) => {
       return Promise.reject(error)
   }
)
axios.interceptors.response.use(
   function (response) {
       // Check if the X-DB-Version header exists
       let dbVersion = response.headers['x-db-version'];
       if (dbVersion == 'kidonv3-db-prod.c1c3obgmz70n.us-east-1.rds.amazonaws.com') dbVersion = 'Production';
       else if (dbVersion == 'kidonv3-db-prod-bi-replica.c1c3obgmz70n.us-east-1.rds.amazonaws.com') dbVersion = 'Replica';
       else if (dbVersion == 'kidonv3-staging-db.c1c3obgmz70n.us-east-1.rds.amazonaws.com') dbVersion = 'Staging';
       else if (dbVersion == 'kidonv3-development-db.c1c3obgmz70n.us-east-1.rds.amazonaws.com') dbVersion = 'Shadow';
      
     
       if (dbVersion) {
           // Dispatch the DB version to the Redux store
           store.dispatch(setDbName(dbVersion));
       }


       return response; // Return the response as usual
   },
   function (error) {
       if (error?.response?.status === HttpCodes.Unauthorized) {
           store.dispatch(signOutSuccess());
       }


       return Promise.reject(error);
   }
);




BaseService.interceptors.response.use((response) => response,(error) => {
  
  
       const { response } = error


       if (response && unauthorizedCode.includes(response.status)) {
           store.dispatch(signOutSuccess())
       }


       return Promise.reject(error)
   }
)


export default BaseService