import { createSlice } from '@reduxjs/toolkit'
import appConfig from '@/configs/app.config'


export type LocaleState = {
   currentLang: string,
   currentDB?: string,
}


const initialState: LocaleState = {
   currentLang: appConfig.locale,
   currentDB: appConfig.dbName,
}


export const localeSlice = createSlice({
   name: 'locale',
   initialState,
   reducers: {
       setLang: (state, action) => {
           state.currentLang = action.payload
       },
       setDbName: (state, action) => {
           state.currentDB = action.payload
       }
   },
})






export const { setLang,setDbName } = localeSlice.actions


export default localeSlice.reducer