import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import type { Industry } from '@/@types/data';

export type IndustriesState = {
    industries: Industry[];
    isLoading: boolean;
    error: string | null;
}

const initialState: IndustriesState = {
    industries: [],
    isLoading: false,
    error: null,
};

const industriesSlice = createSlice({
    name: `${SLICE_BASE_NAME}/industry`,
    initialState,
    reducers: {
        setIndustries: (state, action: PayloadAction<Industry[]>) => {
            state.industries = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const { setIndustries, setLoading, setError } = industriesSlice.actions;

export default industriesSlice.reducer;
